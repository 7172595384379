import React from 'react'
import CareerLayout from '../../components/careerLayout'

export default function AndroidEngineer () {
  return (
    <CareerLayout title='Android Engineer' location='Remote'>
      <h2>Overview</h2>
      <p className='mb'>As an Android engineer at Trexity, you’ll be part of an expanding team. You will be primarily focused on optimizing and making our mobile apps robust, and working on future mobile app plans. You will collaborate closely with the product and engineering teams, taking part in architectural and design discussions, scope planning, customer conversations, development and testing.</p>

      <h2>What you might do</h2>
      <ul className='trx-bullet mb'>
        <li>Identify strategies to improve the performance of our Android driver app</li>
        <li>Implement new features for our drivers in our Android driver app to help keep drivers happy and efficient</li>
        <li>Improve mobile app continual delivery and publishing</li>
        <li>Participate in architecture discussion, providing feedback and/or learning from the engineering team</li>
        <li>Help scope and implement future mobile app plans</li>
        <li>Resolve support requests as needed</li>
      </ul>

      <h2>Experience and skills</h2>
      <ul className='trx-bullet mb'>
        <li>You have at least 5 years of experience building and maintaining software professionally</li>
        <li>You have at least 3 years of experience with the Android SDK (Kotlin or Java) and can demonstrate at least one Android app you helped release</li>
        <li>You either know or are able to demonstrate that you can master JavaScript, and React Native</li>
        <li>Experience with Git or similar SCM tools</li>
        <li>Experience with the command line and associated tools (e.g. Bash, Linux, MacOS)</li>
        <li>Experience working in a fast growth environment</li>
        <li>Bonus if you have experience with cloud technologies or backend technologies (e.g. GCP, AWS, Azure, Postgres, Express, Nodejs)</li>
        <li>Bonus if you have experience with continuous delivery with regards to mobile app development</li>
      </ul>

      <h2>Benefits</h2>
      <ul className='trx-bullet mb'>
        <li>Equal opportunity employer</li>
        <li>Full health benefits from day one</li>
        <li>3 weeks vacation</li>
        <li>Employee Share Option Plan</li>
      </ul>
    </CareerLayout>
  )
}
